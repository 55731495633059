import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../utility/utils"
import CTA from "../../../general/cta"


const Privacy = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])
  return (
    <Fragment>
      <div className="kuda-inner--heading kuda-disclaimer--section">
        <div className="kuda-section--inner general-style">
          <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
            <Link to="/en-ng/legal/" className="color-black mb-3 mt-4 back-page">
              <div className="align-self-center mr-2">
                <div className="f-14">
                  Legal
                </div>
              </div>
              <span className="mr-2">
                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 mr-2 text-left">Nigeria</span>
              <span className="mr-2">
                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 text-bold text-left">Privacy Policy</span>
            </Link>
            <div className="legal-left">
              <h1 className="kuda-section--heading mb-0 text-lg-biz text-xlbold color-primary title-bottom--spacing no-margins">
                Privacy Policy
              </h1>
              {/* <p className="card-main--heading text-semi-bold color-black mb-0">
                Personal Information at Kuda.
              </p> */}
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing mb-4">
              Background
            </h1>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                We’re Kuda Microfinance Bank Limited (‘we’, ‘our’, ‘us’) and
                operate under the name Kuda. We’re registered with the Corporate
                Affairs Commission with the number RC796975.
              </p>
              <p className="color-black bottom--spacing">
                This policy describes what information we collect about you, how
                we collect and use the information you share with us, and with
                whom we share that information. You don’t have to share any
                information with us, but to use our services, we’ll need some
                information from you. This policy also contains information
                about when we share your personal information with third parties
                (such as our service providers and credit bureaus).
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
              What information do we collect?
            </h1>
            <h4 className="card-main--heading color-black text-semi-bold mb-3">
              Information you give us on Kuda Applications
            </h4>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                We collect and use any information you supply when you interact with any of our touch points. When you open an account with us, you share details like your names, Bank Verification Number (BVN), identification documents, address and  pictures. By using our card or any Kuda Application to transact, you also share details of your transactions with us. Additionally, we may request explicit permission to see other information like your address book, location, photos and data from your phone camera. Other details we collect and what we do with them include;
              </p>
              <p className="color-black bottom--spacing">
                <ul className="diclaimer-list">
                  <li>
                    Details you give when you sign up for a Kuda account, like your BVN, names, date of birth, gender,  phone number, residential address, and email address are in fulfillment of regulatory requirements.
                  </li>
                  <li>Your profile picture.</li>
                  <li>
                    We collect a video of your face during a liveness check done to confirm that you're the one opening or upgrading your Kuda account. We share this video with a third-party service provider to facilitate our liveness check.
                  </li>
                  <li>
                    You may choose not to upload a video of your face on the Kuda app, however, this will limit what you can do with your Kuda account as the identification and verification will be incomplete.
                  </li>
                  <li>
                    We will not share the video of your face that we collect during a liveness check with advertisers or anyone else other than the third-party service provider that facilitates the liveness check process.
                  </li>
                  <li>
                    If at any time you choose to withdraw your consent for us to use this video for your liveness check, we will delete it. However, please note that this would affect your ability to use your account as certain services are dependent on us processing your video for the liveness check.{" "}
                  </li>
                  <li>
                    Information you give us through the in-app chat so we can
                    help you.
                  </li>
                </ul>
              </p>

              <h4 className="card-main--heading color-black text-semi-bold mb-3">
                Information you give us when you contact us through other channels
              </h4>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  If you contact us via other means than the in-app chat, we collect the following information so we can answer your questions or take action.
                </p>
                <p className="color-black bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>
                      The phone number you’re calling from and information you give us during the call
                    </li>
                    <li>
                      The email address you use and the contents of your email (and any attachments) sent to us.
                    </li>
                    <li>
                      Public details from your social media profile (like Facebook, Instagram or Twitter) if you reach out to us via these platforms, and the contents of your messages or posts to us.
                    </li>
                  </ul>
                </p>
              </div>

              <h4 className="card-main--heading color-black text-semi-bold mb-3">
                Information we collect when you use Kuda channels
              </h4>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>The mobile network operator and the operating system that you use,</li>
                    <li>Your IP addresses and device ID</li>
                    <li>
                      Your phone contacts so you can make airtime purchases or pay contacts on Kuda
                    </li>
                  </ul>
                </p>
              </div>

              <h4 className="card-main--heading color-black text-semi-bold mb-3">
                Information we get from third parties
              </h4>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  As part of our Know Your Customer (KYC) process, we run checks on the identity  information you supply during signup. We will try to verify the authenticity and validity of the identification document that you have provided, either directly from the issuing authorities or through authorized service providers.
                </p>
                <p className="color-black bottom--spacing">
                  Also, when you request for credit or lending related products and services, we run further checks with the licensed credit bureaus for eligibility checks in line with regulations.
                </p>
              </div>
            </div>

            <div className="kuda-section--100 kuda-spacing--inner">
              <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                How do we use your information?
              </h1>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  The Nigerian Data Protection Regulations 2019 (NDPR) requires that we have a lawful basis for processing your personal information. At least one of the following lawful basis must apply before we process your personal information: contractual or legal obligations, legitimate interest of the data controller, public interest, vital interest of the data subject or consent.
                </p>
              </div>
              <h4 className="card-main--heading color-black text-semi-bold mb-3">
                Contractual Obligation
              </h4>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  We collect certain data from you to fulfill the contract we have with you, or to enter into a contract with you. We use this data to:
                </p>
                <p className="color-black bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>
                      Give you the services we agreed to in line with our terms and conditions.
                    </li>
                    <li>
                      Send you messages about your account and other services you use if you get in touch, or we need to tell you about something..
                    </li>
                    <li>
                      Exercise our rights under contracts we’ve entered into with you, like managing, collecting and recovering money you owe us.
                    </li>
                    <li>
                      Investigate and resolve complaints and other issues.
                    </li>
                  </ul>
                </p>
              </div>
              <h4 className="card-main--heading color-black text-semi-bold mb-3">
                Legal Duty
              </h4>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  We have to ensure we aren’t breaking any laws by banking you by preventing illegal activities like money laundering, terrorism financing and fraud. To do this, we need your data to;
                </p>
                <p className="color-black bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>
                      Confirm your identity when you sign up or get in touch.
                    </li>
                    <li>
                      Prevent illegal activities like money laundering, tax
                      evasion and fraud.
                    </li>
                    <li>
                      Keep records of information we hold about you in line with
                      legal requirements.
                    </li>
                    <li>
                      Adhere to banking laws and regulations (these mean we
                      sometimes need to share customer details with regulators,
                      tax authorities, law enforcement or other third parties).
                    </li>
                  </ul>
                </p>
              </div>

              <h4 className="card-main--heading color-black text-semi-bold mb-3">
                Legitimate Interest of the data controller
              </h4>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  In some instances, we need to use the data you supply us for our legitimate interests. This means we’re using your data in a way that you might expect us to, for a reason which is in your interest and doesn't override your privacy, interests or fundamental rights and freedoms.
                </p>
              </div>

              <h4 className="card-main--heading color-black text-semi-bold mb-3">
                Consent
              </h4>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  For certain information, we’ll ask for your consent. We’ll ask
                  for your consent to:
                </p>
                <p className="color-black bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>
                      Market and communicate our products and services. You can always unsubscribe from receiving these if you want to;
                    </li>
                    <li>
                      Help protect you against fraud by tracking the location of your phone if you’ve authorised it;
                    </li>
                    <li>View your contact list for airtime purchases.</li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="kuda-section--100 kuda-spacing--inner">
              <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                Who do we share your information with?
              </h1>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  In some instances, we will share the information provided with some third parties that we do business with, like card producers and credit bureaus, or with law enforcement agencies when required. Sharing of confidential customer information with these related third parties would be done in a secure manner.
                </p>
              </div>
            </div>

            <div className="kuda-section--100 kuda-spacing--inner">
              <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                How long do we keep your data?
              </h1>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  We keep your information as long as you are a Kuda customer and for any additional period as required under applicable law or regulations.
                </p>
                <p className="color-black bottom--spacing">
                  These retention requirements supersede any right to erasure requests under applicable data protection laws. We cannot therefore erase your data in relation to your Kuda Account until after this time period, however we can assure you that your data will be held safely and securely whilst under our supervision.
                </p>
              </div>
            </div>

            <div className="kuda-section--100 kuda-spacing--inner">
              <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                When do we delete your data?
              </h1>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  We are basically storing and processing your personal data only as long as it is necessary to perform our obligations under the agreement with you or as long as the law requires us to store it.
                </p>
                <p className="color-black bottom--spacing">
                  That means, if the data is not required anymore for statutory or contractual obligations, your data will be deleted.
                </p>
                <p className="color-black bottom--spacing">
                  If you choose to delete your Kuda account, we will delete any data you have previously given us, including the video of your face uploaded during the liveness check. However this is subject to the retention regulations that apply to such data as stipulated by the regulators.
                </p>
              </div>
            </div>

            <div className="kuda-section--100 kuda-spacing--inner">
              <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                What are your rights?
              </h1>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  You have the right to access and request the information that we have on you in our records. You may also ask us to delete your information on our record, restrict the way in which we use your personal information, ask that we update the personal information we hold about you or correct such personal information which you think is incorrect or incomplete, and we will grant this request as long as we’re legally allowed to. You also have the right to object to us using your information for our marketing purposes or any additional services we may be offering you. Lastly, you may also withdraw any consent you’ve previously given us.
                </p>
              </div>
            </div>
            <div className="kuda-section--100 kuda-spacing--inner">
              <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                How to make a complaint
              </h1>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  At Kuda, we’re extremely committed to respecting and protecting your personal information. If you have any worries, reservations or complaints about your personal information, please contact our Data Protection Officer by:
                </p>
                <p className="color-black bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>Sending a message via the app</li>
                    <li>
                      Emailing us at{" "}
                      <a className="color-primary" href="mailto:dpo@kuda.com">
                        dpo@kuda.com
                      </a>
                    </li>
                    <li>
                      Writing to us at 1-11 Commercial Avenue, Yaba, Lagos
                    </li>
                  </ul>
                </p>
                <p className="color-black bottom--spacing">
                  We will do our best to fix the problem.
                </p>
              </div>
            </div>
            <div className="kuda-section--100 kuda-spacing--inner">
              <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                Changes to this document
              </h1>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black bottom--spacing">
                  This document will be reviewed on a yearly basis, or more frequently if occasioned by changes or amendment to applicable data protection regulations. If we make any changes, we’ll add a note to this page and if there are significant changes we’ll let you know by email.
                </p>
                <p className="color-black bottom--spacing">
                  This policy was last updated on 2 November 2022.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="cta-hide"> */}
      <CTA />
      {/* </div> */}
    </Fragment>
  )
}

export default Privacy
